<template>
     <q-card class="col-3 q-ma-md card-product" >
       <q-card-section>
           <div class="q-mt-sm"> 
                <div v-show="product.is_promotion">
                    <div class="promo-card">
                        <span _ngcontent-pra-c37="">Promoção </span>
                    </div>
                    <div _ngcontent-pra-c37="" class="promo-card-back"></div>
                </div>

                <div class="q-pl-md logo-card">
                    <q-img
                    src="@/assets/logo/logo.svg"
                    spinner-color="white"
                    class="image-log"
                    />
                </div>
               
           </div>
            
            <q-img :src="url_aws_bucket + product.image_path" class="image-card-product" />
           
        </q-card-section>  

       <q-card-section class="q-pt-none q-pb-none q-mt-sm">
            <q-item-label caption lines="2" style="font-size:16px;font-weight:bold" >{{ product.product_name }}</q-item-label>
        </q-card-section>


      <q-card-section class="q-pt-none q-mt-sm" v-if="product.is_promotion">
        <div class="text-subtitle1 text-point text-color-old">
             De: {{ product.original_price }} moedas
        </div>
        <div class="text-subtitle1 text-point text-color">
             Por: {{ product.price }} moedas
        </div>
      </q-card-section>
       <q-card-section class="q-pt-none q-mt-sm" v-else>
        <div class="text-subtitle1 text-point text-color-old">
      
        </div>
        <div class="text-subtitle1 text-point text-color">
             {{ product.price }} moedas
        </div>
      </q-card-section>

      <q-card-section class="center">
            <DefaultBtn titleButton="Resgatar" icon="shopping_bag" 
             :query="{ productId: product.product_id}" route="product-details"  />
      </q-card-section>

    </q-card>
</template>

<script>
import { ENV  } from "@/utils/env";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";

export default {
    name: "CardProduct",
    components: {
        DefaultBtn,
    },
    props: {
        product: Object,    
    },
    setup () {

        let url_aws_bucket =ENV.URL_AWS_BUCKET;

        return {
            url_aws_bucket
        }
  }
}
</script>
<style lang="scss" scoped>
.card-product{
    height: 390px
}

.text-color{
    color: var(--q-primary, #fe4e64);
    font-size: 22px;
}

.text-color-old{
    color: #c9c9c9;
    font-size: 20px;
    text-decoration: line-through;
}

.text-point{
    font-weight: 600;
    text-align: center;
    display: block;
    height: 22px;
    line-height: 20px;
}

.center{
    display: flex;
    justify-content: center;
}
.image-card-product{
    background: rgba(196, 196, 196, 0.15);
    border-radius: 15px;
    max-height: 170px;
}


.image-log{
    height: 1.2rem;
    width: 4rem;
}

.logo-card{
    z-index: 999;
    position: absolute;
    display: block;
    right: 10px;
    top: 10px;
}
.promo-card{
    background-color: var(--q-primary, #fe4e64);
    color: rgb(255, 255, 255);
    display: block;
    position: absolute;
    z-index: 999;
    padding: .3em .4em;
    top: .5em;
    left: -.5em;
    border-radius: 3px 3px 3px 0;
}

.promo-card-back {
    position: absolute;
    height: .3em;
    background-size: cover;
    z-index: 998;
    top: 2.3em;
    left: 0;
}

.promo-card-back::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0;
    width: 1.05em;
    left: -.45em;
    -webkit-transform: skewY(45deg);
    transform: skewY(45deg);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    z-index: 990;
    background-color: #000;
}
.promo-card-back::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: .8em;
    width: .5em;
    left: -.5em;
    -webkit-transform: skewY(45deg);
    transform: skewY(45deg);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    z-index: 990;
    background-color: #000;
}
</style>
