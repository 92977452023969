<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="student" :query="{  }">
         <q-btn dense round color="default-pink" flat
                  icon="o_menu_outline" v-if="showStoreConfig">
          <q-menu>
               <q-list style="min-width: 100px">
                  <q-item v-show="validateActionsPage('store-view-providers')" clickable v-close-popup :to="{ name: 'provider-product' }">
                    <q-item-section avatar>
                       <q-icon
                        size="sm"
                        color="default-pink"
                        class="material-icons-outlined"
                        name="apartment"
                      />
                    </q-item-section>
                    <q-item-section>
                      Fornecedores
                    </q-item-section>
                  </q-item>
        
                  <q-item v-show="validateActionsPage('store-view-products')" clickable v-close-popup :to="{ name: 'product' }">
                    <q-item-section avatar>
                       <q-icon
                        size="sm"
                        color="default-pink"
                        class="material-icons-outlined"
                        name="receipt_long"
                      />
                    </q-item-section>
                    <q-item-section>
                      Produtos
                    </q-item-section>
                  </q-item>
           
                  <q-item v-show="validateActionsPage('store-view-warehouse')" clickable v-close-popup :to="{ name: 'warehouse' }">
                    <q-item-section avatar>
                       <q-icon
                        size="sm"
                        color="default-pink"
                        class="material-icons-outlined"
                        name="warehouse"
                      />
                    </q-item-section>
                    <q-item-section>
                      Estoque
                    </q-item-section>
                  </q-item>
           
                  <q-item v-show="validateActionsPage('store')"  clickable v-close-popup :to="{ name: 'order' }">
                    <q-item-section avatar>
                       <q-icon
                        size="sm"
                        color="default-pink"
                        class="material-icons-outlined"
                        name="grading"
                      />
                    </q-item-section>
                    <q-item-section>
                      Meus Pedidos
                    </q-item-section>
                  </q-item>

                  <q-item v-show="validateActionsPage('store')" clickable v-close-popup :to="{ name: 'order-accept' }">
                    <q-item-section avatar>
                       <q-icon
                        size="sm"
                        color="default-pink"
                        class="material-icons-outlined"
                        name="grading"
                      />
                    </q-item-section>
                    <q-item-section>
                      Pedidos
                    </q-item-section>
                  </q-item>
       
                  <q-item v-show="validateActionsPage('store')" clickable v-close-popup :to="{ name: 'orders-for-cancel' }">
                    <q-item-section avatar>
                       <q-icon
                        size="sm"
                        color="default-pink"
                        class="material-icons-outlined"
                        name="delete_forever"
                      />
                    </q-item-section>
                    <q-item-section>
                      Cancelamentos
                    </q-item-section>
                  </q-item>
               </q-list>
            </q-menu>          
        </q-btn>
    </Mural>

    <main class="lesson-content q-ml-md scroll-y" ref="productList">
      <q-scroll-observer @scroll="(e) => onScroll(e)" debounce="400"/>
      <div  class="content-table row flex q-pt-lg">
          <div class="form-input col-12 column">
              <div class="row flex-end q-mr-md">
                 <q-input
                    class="col-3 input-search"
                    bg-color="white"
                    color="default-pink"
                    dense
                    type="text"
                    placeholder="Procure um produto"
                    v-model="searchProduct"
                    @update:model-value="filterProduct()"
                    outlined
                    rounded
                >
                  <template v-slot:prepend>
                    <q-icon name="search" color="default-pink" />
                  </template>
                </q-input>
              </div>
            </div>
        <div class="col-12 q-pa-md row items-start q-gutter-md "  >
          <CardProduct :product="product" :key="index" v-for="(product, index) in products" />
        </div>
      </div>
    </main>
    <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      >
        <div class="collumn justify-center q-my-md">
          <div style="height: auto; width: 50px; margin: auto">
            <LoadingIcon />
          </div>
          <label>Por favor aguarde...</label>
        </div>
      </q-inner-loading>
  </div>

</template>

<script>
import CardProduct from "@/components/store/cards/CardProduct.vue";
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import WareHouseService from '@/services/WareHouseService';
import LoadingIcon from "@/components/LoadingIcon.vue";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

import {ref, onMounted} from "vue";

export default {
  name: "Store",
  components: {
    Mural,
    CardProduct,
    DefaultBtn,
    LoadingIcon
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let _pageNumber = ref(0);
    let breadcrumbs = ref(["Loja"]);
    let products = ref([]);
    let productsAux = ref([]);
    let loading = ref(false);
    let searchProduct = ref("");
    let showStoreConfig = ref(true);
    let canUserAccessClass = new CanUserAccessClass();
    const productList = ref(null)

    async function _getProductWarehouse(){
      _showLoading();
      _pageNumber.value = _pageNumber.value + 1;
      const response = await _wareHouseService.getProductWarehouse(_pageNumber.value, 10);
      if (![204,400].includes(response.status)  ){
        products.value = products.value.concat(response.data)
        productsAux.value = productsAux.value.concat(response.data)
      }
      _hideLoading();
    }

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function _showLoading(){
      loading.value = true;
    }
    
    function _hideLoading(){
      loading.value = false;
    }

    onMounted(async () => {
      _getProductWarehouse();
    });


    function onScroll(scroll) {
      const myElement = productList.value
      const topPos = myElement.scrollHeight - myElement.clientHeight - 1;

      if (topPos < scroll.position.top && scroll.directionChanged === false) {
        _getProductWarehouse()
      }
    }
    
    function filterProduct(){
      var val = searchProduct.value;
      if (val === '') {
        products.value = productsAux.value;
        return
      }
      const needle = val.toLowerCase()
      products.value = productsAux.value.filter(v => v.product_name.toLowerCase().indexOf(needle) > -1)
    }

    return {
      breadcrumbs,
      loading, 
      products,
      searchProduct,
      filterProduct,
      showStoreConfig,
      validateActionsPage,
      productList,
      onScroll
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-card {
  Width: 100%;
  Height: 206px;
  background: #F7F7F7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}
.input-search{
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
}
.flex-end {
	justify-content: flex-end;
}
</style>